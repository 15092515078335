<template>
  <div
    style="width: inherit; height: inherit"
    class="pt-5"
  >
    <v-row wrap>
      <v-btn-toggle
        v-model="x"
        @change="draw"
      >
        <template v-for="item in xs">
          <v-btn
            :key="item.code"
            text
            :value="item.code"
          >
            {{ item.name }}
          </v-btn>
        </template>
      </v-btn-toggle>
      <v-spacer />
      <v-btn
        color="primary"
        :loading="loading || subLoading"
        @click="updateChangedValues"
      >
        Update X Values
      </v-btn>
      <v-btn
        color="green"
        class="white--text ml-1"
        :loading="loading || subLoading"
        @click="updatePredictions"
      >
        Update Predicted Values
      </v-btn>
    </v-row>
    <div
      ref="echart"
      v-resize="onResize"
      style="width: inherit; height: inherit"
    />
  </div>
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import { createOption, symbolSize } from './lib/transformer';
import ApiService from '../../../services/api.service';
import { getSelectedX } from '../../../lib/model';
import { createPredictionQuery } from '../../../lib/query-creator';

let chart = null;
let data = null;
let repository = null;
const load = () => {
  // Add shadow circles (which is not visible) to enable drag.
  chart.setOption({
    graphic: data.map((item, dataIndex) => ({
      type: 'circle',
      position: chart.convertToPixel('grid', item),
      shape: {
        cx: 0,
        cy: 0,
        r: symbolSize / 2,
      },
      invisible: false,
      draggable: true,
      ondrag(dx, dy) {
        // const pos =
        // const d = myChart.convertFromPixel('grid', [this.x, this.y]);
        // this.x = data[dataIndex][0];

        const px = chart.convertToPixel('grid', item);
        this.x = px[0];
        onPointDragging(dataIndex, [this.x, this.y]);
      },
      onmousemove() {
        showTooltip(dataIndex);
      },
      onmouseout() {
        hideTooltip(dataIndex);
        // console.log('dataIndex', dataIndex);
      },
      z: 100,
    })),
  });
};
// window.addEventListener('resize', updatePosition);

function updatePosition() {
  chart.setOption({
    graphic: data.map((item, dataIndex) => ({
      position: chart.convertToPixel('grid', item),
    })),
  });
}
function showTooltip(dataIndex) {
  chart.dispatchAction({
    type: 'showTip',
    seriesIndex: 0,
    dataIndex,
  });
}
function hideTooltip(dataIndex) {
  chart.dispatchAction({
    type: 'hideTip',
  });
}
function onPointDragging(dataIndex, pos) {
  const d = chart.convertFromPixel('grid', pos);
  // pos[]
  // data[dataIndex] = myChart.convertFromPixel('grid', pos);
  // Update data
  data[dataIndex] = [data[dataIndex][0], d[1]];
  chart.setOption({
    series: [
      {
        id: 'a',
        data: [...data],
      },
    ],
  });
}

export default {
  name: 'CalibrateChart',
  components: {},
  props: ['dataset', 'model', 'location'],
  data() {
    return {
      chart: null,
      interval: null,
      xs: [],
      x: null,
      loading: false,
    };
  },
  computed: {
    subLoading: {
      get() {
        return this.$store.state.subLoading;
      },
    },
  },
  mounted() {
    // const model
    // this.draw();
    this.init();
    const xs = getSelectedX(this.model);
    this.xs = xs;
    if (xs.length) {
      this.x = xs[0].code;
    }
    this.draw();
    const that = this;

    // this.interval = setInterval(() => {
    //   that.updateChangedValues();
    // }, 60000);
  },
  beforeDestroy() {
    // clearInterval(this.this.interval);
  },
  methods: {
    init() {
      chart = echarts.init(this.$refs.echart);
    },
    async updateChangedValues(emit) {
      if (repository && data) {
        const changedValues = repository.filter((item, index) => {
          if (data[index][1] !== item.yValue) {
            item.yValue = data[index][1];
            return true;
          }
          return false;
        });
        if (changedValues.length) {
          // console.log('changedValues', changedValues);
          await this.updateTable(changedValues, emit);
        }
      }
    },
    async updateTable(changedValues, update) {
      const column = this.x;
      const values = [];
      for (let i = 0; i < changedValues.length; i++) {
        const changedValue = changedValues[i];
        values.push({ value: changedValue.yValue, index: changedValue.index });
      }
      const tableName = `model_table_${this.model.model_code}_predictions`;
      const q = `UPDATE ${tableName} SET ${column} = :value WHERE "index" = :index`;
      this.loading = true;
      await ApiService.post('/raw_write/', { q, values });
      this.loading = false;
      if (update) {
        this.$emit('calibrated');
      }
    },
    updatePredictions() {
      this.updateChangedValues(true);
    },
    async draw() {
      if (!this.x) {
        return;
      }
      console.log('this.x', this.x);
      this.loading = true;
      const q = createPredictionQuery(this.model, this.location);
      // console.log('q', q);
      const response = await ApiService.post('/raw_sql/', { q });
      // chart = echarts.init(this.$refs.echart);
      const params = {
        xColumn: 'Date',
        yColumn: this.x,
        valueColumn: this.x,
        labelColumn: this.location,
      };

      repository = response.data.map((item) => {
        const result = { ...item };
        result.xValue = item[params.xColumn].valueOf();
        result.yValue = item[params.yColumn];
        return result;
      });

      data = repository.map((item) => [item.xValue, item.yValue]);

      const option = createOption(data, params);
      chart.on('dataZoom', updatePosition);
      // chart.on('mouseup', this.updateChangedValues);
      // chart.on('change', this.updateChangedValues);
      chart.setOption(option);
      load();
      this.loading = false;
    },
    rendered() {},
    onResize() {
      if (chart) {
        // updatePosition();
        // chart.resize();
      }
    },
  },
};
</script>
