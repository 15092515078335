export const symbolSize = 10;

export const createOption = (data, settings) => {
  const {
    xColumn, yColumn, valueColumn, labelColumn,
  } = settings;

  const d = [...data];
  const minX = Math.min(...d.map((item) => item[0]));
  const maxX = Math.max(...d.map((item) => item[0]));
  let minY = Math.min(...d.map((item) => item[1]));
  minY = minY > 0 ? 0 : (minY - minY);
  const maxY = Math.max(...d.map((item) => item[1])) * 2;

  // console.log('d', d);
  const option = {
    title: {
      text: 'Drag Point To Update X Value',
      left: 'center',
    },
    tooltip: {
      triggerOn: 'none',
      formatter(params) {
        return (
          `${params.data[1].toFixed(2)}, ${labelColumn}: ${params.data[0]}`
        );
      },
    },
    grid: {
      top: '8%',
      bottom: '12%',
      right: '2%',
      left: '4%',
    },
    xAxis: {
      type: 'time',
      axisLine: { onZero: false },
    },
    yAxis: {
      min: minY,
      max: maxY,
      type: 'value',
      axisLine: { onZero: false },
    },
    // dataZoom: [
    //   {
    //     type: 'slider',
    //     xAxisIndex: 0,
    //     filterMode: 'none',
    //   },
    //   {
    //     type: 'slider',
    //     yAxisIndex: 0,
    //     filterMode: 'none',
    //   },
    //   {
    //     type: 'inside',
    //     xAxisIndex: 0,
    //     filterMode: 'none',
    //   },
    //   {
    //     type: 'inside',
    //     yAxisIndex: 0,
    //     filterMode: 'none',
    //   },
    // ],
    series: [
      {
        id: 'a',
        type: 'line',
        smooth: true,
        symbolSize,
        data: d,
      },
    ],
  };
  return option;
};
